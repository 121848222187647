import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@sys42/utils";
import {
  ButtonPrimaryLarge,
  Headline3Sans,
  Overline,
} from "@userbrain/website-ui";
import * as styles from "./styles.module.css";

export function ResourceDownloadCard(props) {
  const {
    type,
    title,
    description,
    inverted,
    downloadTo,
    downloadHref,
    imageSrc,
    imageAlt,
    className,
  } = props;
  return (
    <div
      className={cn(
        className,
        styles.resourceDownloadCard,
        inverted && styles.resourceDownloadCard_inverted
      )}
    >
      <img className={styles.image} src={imageSrc} alt={imageAlt} />
      <div className={styles.content}>
        <Overline>{type}</Overline>
        <Headline3Sans className={styles.title}>{title}</Headline3Sans>
        <p>{description}</p>
        <div className={styles.downloadButtonContainer}>
          <ButtonPrimaryLarge
            customTag={Link}
            to={downloadTo}
            href={downloadHref}
          >
            Download
          </ButtonPrimaryLarge>
        </div>
      </div>
    </div>
  );
}
