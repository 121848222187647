import React from "react";

import { concatClassNames as cn } from "@sys42/utils";

import Layout from "../layouts/layout";

import { ResourceDownloadCard } from "../components/ResourceDownloadCard";
import { HeaderTitle } from "../components/HeaderTitle";

import * as helperStyles from "./helpers.module.css";
import { WavySeparator } from "../components/WavySeparator";

import imageGuerrillaUsabilityTestingCheatSheet from "../images/guerrillaUsabilityTestingCheatSheet.jpg";
import imageUsabilityTemplateTaskCompletion from "../images/usabilityTaskCompletionTemplate.jpg";

export default function About() {
  return (
    <Layout
      title={"Resources"}
      metaDescription={
        "Resources to uplevel your user testing game. Grab our templates, cheat sheets, and books to become a user testing pro in no time."
      }
    >
      <article>
        <HeaderTitle
          overline={"Download center"}
          titleWidth={"12.6em"}
          subtitle={
            "Grab our templates, cheat sheets, and books to become a user testing pro in no time."
          }
          subtitleWidth={"23em"}
        >
          <span className={helperStyles.h1Serif}>
            Resources to uplevel your
          </span>{" "}
          user testing <span className={helperStyles.h1Serif}>game.</span>
        </HeaderTitle>

        <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

        <div className={cn(helperStyles.spacingMd, helperStyles.container)}>
          <ResourceDownloadCard
            type={"Template"}
            title={"Usability Template Task Completion"}
            imageSrc={imageUsabilityTemplateTaskCompletion}
            imageAlt={"Usability Template Task Completion"}
            downloadTo={"/resources/guerilla-usability-testing-cheat-sheet"}
            description={
              "Task completion rate is one of the biggest indicators whether your product is working — or not. This tidy table helps evaluate your tests and keep an overview of results in one place."
            }
            inverted={false}
          />

          <ResourceDownloadCard
            className={helperStyles.spacingMd}
            type={"Cheat sheet"}
            title={"Guerrilla Usability Testing Cheat Sheet"}
            imageSrc={imageGuerrillaUsabilityTestingCheatSheet}
            imageAlt={"Guerrilla Usability Testing Cheat Sheet"}
            downloadTo={"/resources/guerilla-usability-testing-cheat-sheet"}
            description={
              "If you’re new to user testing this one’s for you. Our guerrilla usability testing cheat sheet navigates you through the most important steps of user testing by getting you to set up, test, evaluate, and improve your website."
            }
            inverted={true}
          />
        </div>
      </article>
    </Layout>
  );
}
